@charset "utf-8";
/* CSS Document */
body {
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color:#CCC;
    background-image:url(../../images/main.jpg);
}
.content {
    background-color: transparent;
}
.col-l { color: #F00; }
.bg-col-l { background-color: #F00; }
.col-d { color: #B00; }
.bg-col-d { background-color: #1e2734; }
.btn-default, .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .btn-default:active:hover, .btn-default.disabled, .btn-default.disabled:hover { border-color: #B00; background-color: #B00;} 
a { color: #000; }
a:focus, a:hover { color: #ED1C24; text-decoration: none; }
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	border-color: #FFF;
}
.nav > li > a:hover, .nav > li > a:focus {
	background-color: rgba(0,0,0, 0.1);
	text-shadow:0 0 0 #FFF;
	box-shadow: inset 0 0 10px rgba(0,0,0,0.1); color:#FFF;
}

.nav > li > a:link { transition: all 0.3s ease 0s; }
.nav .glyphicon, .nav .fa {
	color:#FFF;
}
.navbar { border-top: 0px solid #ED1C24; border-bottom:0px solid #ED1C24; }
.www-tworzywa-biz, .www-tworzywa-net, .www-roboty-biz, .www-polimery-biz, .www-polimery-info { background-color:#ED1C24 !important; background-image:none; }
.www-recykling-biz { background-color:#30b986 !important; background-image:none; }
.www-opakowania-biz-pl, .www-pojemniki-info, .www-butelki-biz { background-color:#308db9 !important; background-image:none; }
.wtryskarki-dev, .www-wtryskarki-biz, .www-zgrzewarki-biz { background-color:#dab633 !important; background-image:none; }
.navbar-sticky { border-bottom:  0px solid #ED1C24; border-top: 0px solid #ED1C24; }
.nav-head .navbar-toggle { border-color: transparent; }
.nav-head .navbar-toggle .icon-bar { background-color:#FFF; }
.nav-head a { color: #FFF; }
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0px;
    margin: 2px 0px 0px;
    font-size: 12pt;
    text-align: left;
    list-style: outside none none;
    background: #333 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAMgCAMAAACajvtlAAAAdVBMVEVMaXEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4fyL+AAAAJ3RSTlMALCsqKSgnJiUkIyIhIB8eHRYVFBMSERAPDg0MCwoJCAcGBQQDAgHOUHu8AAADDklEQVR4AeyNB6rtMBQD9Xqvt/d+97/Ebw8x+Aej17thGBQdB2kwuPLo4eDBo8OHB4946RB2CBs+faWu1JW68vhIgMfg9qdo4jNoHhAaKwRDeCyCQ9ghbPj0lUMgQ/pU8QG5Qdgh7BA2fNlKXel0nlrpHHbwI+4AV0rROIQdwo66Ulfef+WovdI97HaPOt2jbidwGEO3KUE0QBmeNe8Twg5hh7Dhl670CteeXUl/Caf3JdQ76hWALhZ2CDuEHX9u5RhSQ86DYgDKFqwQHMIOYYeO+0+u9HnTz0sayoDSFSfIwErfI+wQdvzRlROcoIFjYXLqMZBFcAg7hB2vXRlkWYOTgUfYIeyoKz96ZTgMTmF4MhxE8xmygj3CDmHHc1ZOhwEyIf+kEY1D2CHs+K0ro/LKiD6aMCInRxSCR9gh7PgzK2cR4dSUEHYIO4Th9Svjs/H4bITHo5ZB+WcRYYewQxi+YKWuTArXyfh8rPPJxCPsEHZ8g5W6Mi2tTKf/k54Fg+gdwg5hx69amU0vppHz2ewCzqcEHEuF4BF2CBu+aKWuzGOYZyUZQPOLuUfYIWx435XLRMrACsEg7BB2/LaVxeUiMI+EPMcETuLqEHYIG77vSl1ZXkUWgcvl8mq5vIRwDTmcFOwRdggbvvlKXVlFQliRm4ZPra5WHmGHsOEtK9cpiJzDNUPYIewQdvy1lfW6yevgFChhrZDjKTgPifApgkPYIexIKze4Bb3CySPsEHYIZ9SVTZMJmxRgw8pmQ8815PwZQaH0CDuEHSr3dWWLC2i7vQlsgEzIEHYIO4Qdwg7dEnBGVooAWY8bhB3CDmHDh6zUld3udrfFJbY7ERzCDmFHXXnFyt3zV8qPRcm1QFrxCDuEDe++si+v7Pd3kR3OoQwoa3a4xU4Eh7BD2FFX6sofWxGQCSIT1ATgKsp8RfIIvzu/YOW+nFnx8B7Ir0L4Ffxrbw5IAAAAAAT9f92OwAVSKBQKhUKhUCgUykShUCgUCoVCoVAoFAqFQqFQKBQKhUKhUCgUCoVCoVAoFAol/PlB0PhRmj0AAAAASUVORK5CYII=) center 0;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176), inset 0 0 0px rgba(0,0,0,1);
}

.margin20 { margin-top: 85px; }
#particle-canvas{position:relative; width: 100%; height:100%;}
.parallax-container { height:280px; mix-blend-mode:normal; }
.head-text { height:280px; display: table; text-align: center; }
.head-grad { background: /*url(/images/diag-pion.png) 0 bottom,*/ linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) repeat scroll 0% 0%; }
.firma h1, .firma h2, .firma h3, .firma h4  { font-size:60pt; font-weight: 700; text-transform:uppercase; margin-top: 35px; }
.firma h1::after , .firma h3::after{ content:'...'; font-size: 191pt; line-height:1; position: absolute; bottom:20px; left:0; right:0; color: #CD6600; text-shadow: none; letter-spacing: -15px; }
.firma a { color:#FFF; }
.head-img { height:245px;  position:absolute; top:0; left:0; right:0;}
.head-img h2, .head-img h4, .head-img p { font-size: 26pt; font-weight: 100; font-style: italic; text-transform:uppercase; padding:0;}
.title, .title h1, .title h3 { border-bottom:2px solid #B00; }
article h1 { margin-top: 10px; margin-bottom: 0; font-weight:900; }
article h2 { font-size:14pt;  margin-top: 0px; margin-bottom: 20px; font-weight:400; line-height:1.2; }
.lazy{display:inline;}
@media screen and (max-width: 767px){
.margin20 { margin-top: 0; }
.navbar { border-bottom: 0; border-top: 0;}
.head-text { height:185px; }
.firma h1, .firma h2, .firma h3, .firma h4 { font-size:40pt; }
.firma h1::after, .firma h3::after { bottom: -21px; }
.head-img h2, .head-img h4, .head-img p { font-size: 12pt; }
}